import map from "lodash/map";
import React from "react";
import { graphql } from "gatsby";
import Service from "../components/Service";

class ServiceTemplate extends React.Component {
  render() {
    const {
      location,
      data: { site, service, testimonials },
    } = this.props;
    const siteTitle = site.siteMetadata.title;

    return (
      <Service
        location={location}
        siteTitle={siteTitle}
        service={service}
        testimonials={map(
          testimonials && testimonials.edges,
          (edge) => edge.node
        )}
      />
    );
  }
}

export default ServiceTemplate;

export const pageQuery = graphql`
  query ServiceBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    service: markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
      }
    }
    testimonials: allMarkdownRemark(
      filter: {
        fields: { slug: { glob: "/testimonials/**" } }
        frontmatter: { services: { eq: $slug } }
      }
    ) {
      edges {
        node {
          id
          html
          frontmatter {
            title
            imgUrl
            date(formatString: "MMMM DD, YYYY")
          }
        }
      }
    }
  }
`;
