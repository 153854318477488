import PropTypes from "prop-types";
import React from "react";
import Layout from "../common/Layout";
import SEO from "../common/SEO";
import Hero from "../common/Hero";
import Content from "../common/Content";
import MarkdownContent from "../common/MarkdownContent";
import Testimonials from "../common/Testimonials";
import styles from "./Service.module.scss";

class Service extends React.Component {
  render() {
    const { location, siteTitle, service, testimonials } = this.props;
    return (
      <Layout location={location} title={siteTitle}>
        <SEO
          title={service.frontmatter.title}
          description={service.frontmatter.description}
          keywords={[service.frontmatter.title]}
        />
        <Hero title={service.frontmatter.title} />
        <Content>
          <MarkdownContent html={service.html} />
        </Content>
        {testimonials.length > 0 && (
          <div className={styles.testimonials}>
            <Content>
              <h3 className={styles.testimonialsTitle}>
                Here's what our customers had to say...
              </h3>
              <Testimonials service={service} testimonials={testimonials} />
            </Content>
          </div>
        )}
      </Layout>
    );
  }
}

Service.propTypes = {
  location: PropTypes.object.isRequired,
  siteTitle: PropTypes.string.isRequired,
  service: PropTypes.object.isRequired,
  testimonials: PropTypes.array.isRequired,
};

export default Service;
